import axios from './axios';

// const baseApiUrl = process.env.REACT_APP_API_URL;
const endPoint = '/content';


export const getPrivacyPolicyContent = () => {
    return axios.get(`${endPoint}/privacy-policy`);
}

export const getRefundPolicyContent = () => {
    return axios.get(`${endPoint}/refund-policy`);
}

export const getTAndCsContent = () => {
    return axios.get(`${endPoint}/terms-condition`);
}