import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';

import Header from './components/header/Header.component';
import Home from './pages/home/Home.component';
import Booking from './pages/booking/Booking.component';
import VenueHire from './pages/venue-hire/VenueHire.component';
// import MembershipNew from './pages/membership-new/MembershipNew.component';
import MembershipPurchaseResponse from './pages/membership-purchase-response/MembershipPurchaseResponse.component';
import Membership from './pages/membership/Membership.component';
// import MembershipPurchaseSuccess from './pages/membership-purchase-success/MembershipPurchaseSuccess.component';
// import MembershipPurchaseFailed from './pages/membership-purchase-failed/MembershipPurchaseFailed.component';
// import NewsEvents from './pages/news-events/NewsEvents.component';
// import NewsArticle from './pages/news-article/NewsArticle.component';
import Account from './pages/account/Account.component';
import AccountSignUpIn from './pages/account-sign-up-in/AccountSignUpIn.component';
import AccountSignUp from './pages/account-sign-up/AccountSignUp.component';
import AccountSignIn from './pages/account-sign-in/AccountSignIn.component';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy.component';
import RefundPolicy from './pages/refund-policy/RefundPolicy.component';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions.component';
import Footer from './components/footer/Footer.component';
import Checkout from './pages/checkout/Checkout.component';
import OrderSuccess from './pages/order-success/OrderSuccess.component';
import OrderFailed from './pages/order-failed/OrderFailed.component';

/** list of modals to show/display to the client */
import VenueEnquire from './modal-pages/venue-enquire/VenueEnquire.component';
import ContactUs from './modal-pages/contact-us/ContactUs.component';
// import MembershipCancel from './modal-pages/membership-cancel/MembershipCancel.component';
// import MembershipResume from './modal-pages/membership-resume/MembershipResume.component';
import ForgottenPassword from './modal-pages/forgotten-password/ForgottenPassword.component';
import ResetPassword from './pages/reset-password/ResetPassword.component';

import AppContext from './context/app/app.context';
import { getProjectSync } from './services/sync.service';
import { decryptData } from './services/utils.service';


function App() {

  const { setAppConfig, popupModalMenu } = useContext(AppContext);
  const [isRequestAlive, setIsRequestAlive] = useState(true);

  /** component will-mount */
  useEffect(() => {
    const encodeKey = `${window.screen.height}${window.screen.width}${window.screen.colorDepth}${new Date().getTime()}`;
    const body = {};
    if (localStorage.getItem('token')) {
      body.token = localStorage.getItem('token');
    }

    setIsRequestAlive(true);
    getProjectSync(body, encodeKey).then((res) => {
      if (res.data.code === "OK") {
        const decryptBody = decryptData(res.data.data);
        setAppConfig({ ...decryptBody });
        setIsRequestAlive(false);
      }
    }).catch((err) => {
      setIsRequestAlive(false);
      console.log('Error while sync data', err);
    });
    // eslint-disable-next-line
  }, []);

  const getModalObject = (name) => {
    return popupModalMenu.find((item) => item.name === name);
  };

    return (
      <>
        {!isRequestAlive && /** load all the routes and other pages(modal) after 'sync' API call */
          <>
            <div className="app-container">
  
              <div className='app-header'>
                <Header></Header>
              </div>
  
              <div className='app-body-footer-wrapper'>
                <div className='app-body'>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/booking" element={<Booking />} />
                    {/* <Route path="/venue-hire" element={<VenueHire />} /> */}
                    <Route path="/membership" element={<Membership />} />
                    <Route path="/membership-purchase-response" element={<MembershipPurchaseResponse />} />
                    {/* <Route path="/membership" element={<Membership />} />
                    <Route path="/membership-purchase-success" element={<MembershipPurchaseSuccess />} />
                    <Route path="/membership-purchase-failed" element={<MembershipPurchaseFailed />} /> */}
                    {/* <Route path="/news" element={<NewsEvents />} />
                    <Route path="/news/:newsArticleId" element={<NewsArticle />} /> */}
                    <Route path="/account" element={<Account />} />
                    <Route path="/account-sign-up-in" element={<AccountSignUpIn />} />
                    <Route path="/account-sign-up" element={<AccountSignUp />} />
                    <Route path="/update-my-information" element={<AccountSignUp />} />
                    <Route path="/account-sign-in" element={<AccountSignIn />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/order-success" element={<OrderSuccess />} />
                    <Route path="/order-failed" element={<OrderFailed />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </div>
  
                <div className='app-footer'>
                  <Footer></Footer>
                </div>
              </div>
  
            </div>
            {/* for popup modal component, because it should be displayed on any current screen if it opened via menu. */}
            <div className='popup-modal-component-wrapper'>
              {getModalObject('venueEnquire')?.isShow ? <VenueEnquire /> : null}
              {getModalObject('contact')?.isShow ? <ContactUs /> : null}
              {getModalObject('forgottenPassword')?.isShow ? <ForgottenPassword /> : null}
              {/* {getModalObject('membershipCancel')?.isShow ? <MembershipCancel /> : null}
              {getModalObject('membershipResume')?.isShow ? <MembershipResume /> : null} */}
            </div>
          </>}
      </>
    );
}

export default App;
