import React, { useEffect, useState } from 'react';

import './RefundPolicy.component.scss';
import LOADER from '../../assets/images/LOADING_ICON.gif';

import { getRefundPolicyContent } from '../../services/privacy-policy-or-t-and-cs.service';


const RefundPolicy = () => {

    const [RefundPolicy, setRefundPolicy] = useState(null);
    const [isRequestAlive, setIsRequestAlive] = useState(true);

    useEffect(() => {
        try {
            setIsRequestAlive(true);
            getRefundPolicyContent().then((res) => {
                if (res.data.code === "OK") {
                    setRefundPolicy(res.data.data);
                    setIsRequestAlive(false);
                }
            }).catch((err) => {
                setIsRequestAlive(false);
                console.log('Error while getting refund policy', err);
            });
        } catch (error) {
            console.log('Error while getting refund policy', error);
        }
    }, []);

    return (
        <>
            {!isRequestAlive && RefundPolicy &&
                <div className='refund-policy-container'>

                    <div className='refund-policy-head'>
                        <div className='refund-policy-head-wrapper'>
                            <h4 className='refund-policy-head-title'>refund-policy</h4>
                            {RefundPolicy?.shortIntro && <h4 className='refund-policy-head-description'>{RefundPolicy.shortIntro}</h4>}
                        </div>
                    </div>

                    <div className='refund-policy-description' dangerouslySetInnerHTML={{ __html: RefundPolicy.content }}></div>
                </div>
            }
            {isRequestAlive &&
                <div className='refund-policy-loader'>
                    <img className='refund-policy-loader-img' src={LOADER} alt='loader' />
                </div>
            }
        </>
    )
}

export default RefundPolicy;